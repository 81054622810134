// giusp.com grn@giusp.com

// The call of this function cannot be delayed: it has to be called just after the
// div for the Typed.js module is created
function initTypedJS(iwlts, colorMap) {
    /*
    Initializes the Typed.js module
    */

    // Create a single array with the strings and the colors in pairs
    coloredIwlts = [];
    for (let i = 0; i < iwlts.length; i++) {
        coloredIwlts.push({ string: iwlts[i], color: colorMap[i] });
    }

    // Randomize the coloredIwlts array
    coloredIwlts.sort(() => Math.random() - 0.5);

    // Separate the strings and the colors
    iwlts = [];
    colorMap = [];
    for (let i = 0; i < coloredIwlts.length; i++) {
        iwlts.push(coloredIwlts[i].string);
        colorMap.push(coloredIwlts[i].color);
    }

    // Prepare "data-typed" string
    var typed = new Typed("#typedJS", {
        strings: iwlts,
        typeSpeed: 30,
        backSpeed: 30,
        backDelay: 700,
        loop: true,
        // Refer: https://github.com/mattboldt/typed.js/issues/621
        smartBackspace: false,
        preStringTyped: function (pos, self) {
            const element = document.getElementById("typedJS");
            const color = colorMap[pos];
            element.className = `text-${color}`;
        },
    });
}
window.initTypedJS = initTypedJS;

function pickTryBySearching(iwlts) {
    /*
    Picks randomly an iwlt to be displayed as "Try by searching" phrase.
    */

    // Pick a random element from the strs array
    let i = Math.floor(Math.random() * iwlts.length);

    // Get the string at that index
    let iwlt = iwlts[i];

    // Inject the value
    document.getElementById("pickTryBySearching").innerHTML += iwlt;
}

function search(searchDB) {
    const options = {
        includeScore: true,
        keys: [
            "service_path",
            "service_name",
            "service_description",
            "service_type",
            "service_data_retention_type",
        ],
    };

    // Prefiltering
    // Get the checkboxes. Build an array with the selected categories. To build this array, get
    // the checked checkboxes inside the div with id "enhancedFilteringCategories"
    let checkboxes = document
        .getElementById("enhancedFilteringCategories")
        .getElementsByTagName("input");
    let selectedCategories = [];

    for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
            selectedCategories.push(checkboxes[i].value);
        }
    }

    // If no category is selected then show all the categories
    if (selectedCategories.length === 0) {
        selectedCategories = searchDB.map(
            (item) => item.service_type
        );
    }

    let filteredData = searchDB.filter((item) => {
        return selectedCategories.includes(item.service_type);
    });

    // Get the radio buttons of popularity and age
    let popularity = document
        .getElementById("enhancedFilteringPopularity")
        .getElementsByTagName("input")[0].checked;
    let age = document
        .getElementById("enhancedFilteringAge")
        .getElementsByTagName("input")[0].checked;

    filteredData.sort((a, b) => {
        // Sort by popularity
        if (popularity) {
            if (a.service_popularity_score !== b.service_popularity_score) {
                return b.service_popularity_score - a.service_popularity_score;
            }
        } else {
            if (a.service_popularity_score !== b.service_popularity_score) {
                return a.service_popularity_score - b.service_popularity_score;
            }
        }

        // If popularity is equal, sort by age
        if (age) {
            return a.service_creation_score - b.service_creation_score;
        } else {
            return b.service_creation_score - a.service_creation_score;
        }
    });

    const e = document.getElementById("search-bar").value;
    const results = new Fuse(filteredData, options).search(e);

    // Update the UI: modify the shown cards
    let rowServicesCards = document.getElementById("rowServicesCards");

    // Clear the row
    rowServicesCards.innerHTML = "";

    // Add the cards
    function schemaComposer(result) {
        let outerCard = document.createElement("div");
        outerCard.classList.add("col-12", "col-md-6", "col-lg-4");

        let card = document.createElement("a");
        card.classList.add("card-body", "mt-auto");
        card.setAttribute("href", result.service_path + "/");

        let cardInner = document.createElement("div");
        cardInner.classList.add(
            "card",
            "card-border",
            `border-${result.service_type_color}`,
            "shadow-lg",
            "mb-6",
            "mb-md-8",
            "lift",
            "lift-lg"
        );

        let cardBody = document.createElement("div");
        cardBody.classList.add(
            "card-body",
            "text-center",
            "href=" + result.service_path + "/"
        );

        let heading = document.createElement("h4");
        heading.classList.add("fw-bold");
        heading.textContent = result.service_name;

        let text = document.createElement("p");
        text.classList.add("text-gray-700", "mb-5");
        text.textContent = result.service_description;

        let badge1 = document.createElement("span");
        badge1.classList.add(
            "badge",
            "rounded-pill",
            `text-bg-${result.service_type_color}-subtle`
        );
        let badge1Span = document.createElement("span");
        badge1Span.classList.add("h6", "text-uppercase");
        badge1Span.textContent = result.service_type;
        badge1.appendChild(badge1Span);

        let space = document.createTextNode(" ");  // Create a text node with a space

        let badge2 = document.createElement("span");
        badge2.classList.add(
            "badge",
            "rounded-pill",
            `text-bg-${result.service_data_retention_type_color}-subtle`
        );
        let badge2Span = document.createElement("span");
        badge2Span.classList.add("h6", "text-uppercase");
        badge2Span.textContent = result.service_data_retention_type;
        badge2.appendChild(badge2Span);

        cardBody.appendChild(heading);
        cardBody.appendChild(text);
        cardBody.appendChild(badge1);
        cardBody.appendChild(space);  // Append the space
        cardBody.appendChild(badge2);

        cardInner.appendChild(cardBody);
        card.appendChild(cardInner);
        outerCard.appendChild(card);

        rowServicesCards.appendChild(outerCard);
    }


    // If no text is in the search bar just show everything that is
    // in the selected categories
    if (e === "") {
        filteredData.forEach(item => schemaComposer(item));
    } else {
        results.forEach(result => schemaComposer(result.item));
    }
}

function populateServiceTypes(serviceTypes) {
    let checkboxList = document.getElementById("enhancedFilteringCategories");

    serviceTypes.forEach((wcategory) => {
        let div = document.createElement("div");
        div.classList.add("form-check", "w-100");

        let input = document.createElement("input");
        input.classList.add("form-check-input");
        input.setAttribute("type", "checkbox");
        input.setAttribute("value", wcategory);
        input.setAttribute("id", wcategory);

        let label = document.createElement("label");
        label.classList.add("form-check-label", "w-100");
        label.setAttribute("for", wcategory);
        label.textContent = wcategory;

        div.appendChild(input);
        div.appendChild(label);

        checkboxList.appendChild(div);
    });
}

document.addEventListener("DOMContentLoaded", function () {
    // Get the passthrough data
    let iwlts = JSON.parse(document.getElementById("iwlts").textContent);
    let colorMap = JSON.parse(document.getElementById('colorMap').textContent);
    let serviceTypes = JSON.parse(document.getElementById("serviceTypes").textContent);
    let searchDB = JSON.parse(document.getElementById("searchDB").textContent);
    let localeEnhancedFilteringCategoriesNoFiltering = JSON.parse(
        document.getElementById("lEFCNF").textContent
    );

    initTypedJS(iwlts, colorMap);
    pickTryBySearching(iwlts);
    populateServiceTypes(serviceTypes);

    // Add the event listener to the search bar
    document.getElementById("search-bar").addEventListener("input", function (e) {
        search(searchDB);
    });

    function checkboxesPrepare() {
        // Add the event listener to the checkboxes
        let checkboxes = document
            .getElementById("enhancedFilteringCategories")
            .getElementsByTagName("input");

        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].addEventListener("change", function (e) {
                document.getElementById("search-bar").dispatchEvent(new Event("input"));
            });
        }
        // Select the checkboxes based on the GET parameters on the URL
        let urlParams = new URLSearchParams(window.location.search);
        let selectedCategories = urlParams.getAll("catfilt");

        for (let i = 0; i < checkboxes.length; i++) {
            if (selectedCategories.includes(checkboxes[i].value)) {
                checkboxes[i].checked = true;
            }
        }
    }

    checkboxesPrepare();

    function radiobuttonsPrepare() {
        // Add the event listener to the radio buttons of enhancedFilteringPopularity div
        let radiouttons = document
            .getElementById("enhancedFilteringPopularity")
            .getElementsByTagName("input");

        for (let i = 0; i < radiouttons.length; i++) {
            radiouttons[i].addEventListener("change", function (e) {
                document.getElementById("search-bar").dispatchEvent(new Event("input"));
            });
        }

        // Write the selected popularity in the p with tag enhancedFilteringPopularitySelected,
        // if selected or remove it if not
        let selectedPopularityP = document.getElementById(
            "enhancedFilteringPopularitySelected"
        );

        for (let i = 0; i < radiouttons.length; i++) {
            radiouttons[i].addEventListener("change", function (e) {
                if (e.target.checked) {
                    selectedPopularityP.innerHTML = e.target.value;
                }
            });
        }

        // Trigger the change event on radio buttons
        radiouttons[0].dispatchEvent(new Event("change"));
    }

    radiobuttonsPrepare();

    function radiobuttonsAgePrepare() {
        // Add the event listener to the radio buttons of enhancedFilteringAge div
        let radiobuttonsAge = document
            .getElementById("enhancedFilteringAge")
            .getElementsByTagName("input");

        for (let i = 0; i < radiobuttonsAge.length; i++) {
            radiobuttonsAge[i].addEventListener("change", function (e) {
                document.getElementById("search-bar").dispatchEvent(new Event("input"));
            });
        }

        // Write the selected age in the p with tag enhancedFilteringAgeSelected, if selected or remove it if not
        let selectedAgeP = document.getElementById("enhancedFilteringAgeSelected");
        for (let i = 0; i < radiobuttonsAge.length; i++) {
            radiobuttonsAge[i].addEventListener("change", function (e) {
                if (e.target.checked) {
                    selectedAgeP.innerHTML = e.target.value;
                }
            });
        }

        // Trigger the change event on radio buttons
        radiobuttonsAge[0].dispatchEvent(new Event("change"));
    }

    radiobuttonsAgePrepare();

    function writerCheckedCheckboxesFilteringCategoriesPrepare() {
        document
            .getElementById("enhancedFilteringCategories")
            .addEventListener("change", function (e) {
                // Get the checkboxes
                checkboxes = document
                    .getElementById("enhancedFilteringCategories")
                    .getElementsByTagName("input");

                // Filter the checkboxes array and keep only the checked ones
                checkboxes = Array.from(checkboxes).filter(
                    (checkbox) => checkbox.checked
                );

                let selectedCategoriesP = document.getElementById("enhancedFilteringCategoriesSelected");
                selectedCategoriesP.innerHTML = "";

                // Iterate over the checkboxes
                for (let i = 0; i < checkboxes.length; i++) {
                    // Is this the last for iteration?
                    if (i === checkboxes.length - 1) {
                        selectedCategoriesP.innerHTML += ` <span class="text-body-secondary mb-0 ` +
                            `text-uppercase">${checkboxes[i].value}</span>`;
                    } else {
                        selectedCategoriesP.innerHTML += ` <span class="text-body-secondary mb-0 ` +
                            `text-uppercase">${checkboxes[i].value},</span>`;
                    }
                }

                if (selectedCategoriesP.innerHTML === "") {
                    selectedCategoriesP.innerHTML = ` <span class="text-body-secondary mb-0 ` +
                        `text-uppercase">${localeEnhancedFilteringCategoriesNoFiltering}</span>`;
                }
            });

        // Write "No filtering" in the p with tag enhancedFilteringCategoriesSelected
        let selectedCategoriesP = document.getElementById("enhancedFilteringCategoriesSelected");
        selectedCategoriesP.innerHTML = ` <span class="text-body-secondary mb-0 text-uppercase">` +
            `${localeEnhancedFilteringCategoriesNoFiltering}</span>`;

        // Trigger the change event on enhancedFilteringCategories
        document
            .getElementById("enhancedFilteringCategories")
            .dispatchEvent(new Event("change"));
    }

    writerCheckedCheckboxesFilteringCategoriesPrepare();

    // Trigger the input event to show all the services
    document.getElementById("search-bar").dispatchEvent(new Event("input"));
});
